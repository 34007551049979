<template>
  <v-container fill-height>
    <v-row justify="center" align="center" no-gutters>
      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-alert color="red lighten-1" type="error" v-if="alerts" dense>
            {{ alerts }}
          </v-alert>
          <v-alert color="green lighten-1" type="success" v-if="messages" dense>
            {{ messages }}
          </v-alert>
          <v-alert type="info" dense>
            填写过程中请善用暂存功能，注意当有格式不正确的输入时无法暂存
          </v-alert>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-row justify="center" align="center" no-gutters>
          <v-card min-width="640">
            <v-toolbar flat color="purple" dark>
              <v-toolbar-title>夏令营信息填报</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  color="purple lighten-2"
                  :disabled="!formValid"
                  @click="handleSave(false)"
                  v-if="infoStatus == 'saved' || infoStatus == 'returned' || infoStatus == ''"
                >
                  暂存
                </v-btn>
                <v-btn color="purple lighten-2" to="/apply/detail" v-else>返回</v-btn>
              </v-card-actions>
            </v-toolbar>
            <v-form
              ref="form"
              v-model="formValid"
              @submit.prevent="disableSubmit()"
              lazy-validation
            >
              <v-tabs vertical slider-color="purple" color="purple" v-model="tab">
                <v-tab ripple href="#basic">
                  <v-icon left> mdi-account </v-icon>
                  基本信息
                </v-tab>
                <v-tab ripple href="#contact">
                  <v-icon left> mdi-account-box </v-icon>
                  联系信息
                </v-tab>
                <v-tab ripple href="#school">
                  <v-icon left> mdi-school </v-icon>
                  学校信息
                </v-tab>
                <v-tab ripple href="#degree">
                  <v-icon left> mdi-book-education </v-icon>
                  学位信息
                </v-tab>
                <v-tab ripple href="#gpa">
                  <v-icon left> mdi-bookshelf </v-icon>
                  成绩信息
                </v-tab>
                <v-tab ripple href="#curriculum">
                  <v-icon left> mdi-table-edit </v-icon>
                  课程成绩
                </v-tab>
                <v-tab ripple href="#statement">
                  <v-icon left> mdi-pencil </v-icon>
                  个人简述
                </v-tab>
                <v-tab ripple href="#recommend">
                  <v-icon left> mdi-email </v-icon>
                  推荐信息
                </v-tab>
                <v-tab ripple href="#upload">
                  <v-icon left> mdi-file </v-icon>
                  上传材料
                </v-tab>
                <v-tab ripple href="#other">
                  <v-icon left> mdi-information </v-icon>
                  其他信息
                </v-tab>

                <v-dialog persistent v-model="deleteDialog" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">删除/Remove</v-card-title>
                    <v-card-subtitle v-if="deleteField">
                      确认要删除已经上传的 {{ fileFields[deleteField].desc }} 吗？</v-card-subtitle
                    >
                    <v-card-text v-if="deleteField">
                      <p>文件名: {{ userFiles[deleteField].name }}</p>
                      <p>文件大小: {{ userFiles[deleteField].size }} bytes</p>
                      <p>上传时间: {{ userFiles[deleteField].time }}</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                      <v-btn color="grey" @click="deleteClose">Cancel</v-btn>
                      <v-btn color="primary" @click="deleteConfirm">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-dialog persistent v-model="uploadDialog" max-width="500px">
                  <v-card>
                    <v-card-title class="headline">上传/Upload</v-card-title>
                    <v-card-subtitle v-text="uploadDesc"> </v-card-subtitle>

                    <v-card-text>
                      <v-file-input
                        ref="fileupload"
                        @change="selectFile"
                        chips
                        show-size
                        counter
                        :disabled="isUploading"
                        :accept="uploadTypes"
                      ></v-file-input>
                    </v-card-text>
                    <v-card-actions v-if="isUploading" class="justify-center">
                      <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-card-actions>
                    <v-card-actions v-else class="justify-center">
                      <v-btn color="grey" @click="uploadClose">Cancel</v-btn>
                      <v-btn color="primary" :disabled="!uploadFile" @click="uploadSubmit"
                        >OK</v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-tab-item value="basic">
                  <v-card flat>
                    <v-card-text>
                      <v-text-field
                        v-model="userCampInfo.name_cn"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.basic.name_cn"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.first_name"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.basic.first_name"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.last_name"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.basic.last_name"
                        required
                      ></v-text-field>

                      <v-select
                        v-model="userCampInfo.gender"
                        :rules="rules.required"
                        :label="infoFields.basic.gender"
                        :items="selectGender"
                        item-text="desc"
                        item-value="value"
                        required
                      >
                      </v-select>

                      <v-menu
                        ref="birthdayMenu"
                        v-model="birthdayMenu"
                        :close-on-content-click="false"
                        :return-value.sync="userCampInfo.birthday"
                        top
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="userCampInfo.birthday"
                            :label="infoFields.basic.birthday"
                            prepend-icon="mdi-calendar"
                            :rules="rules.required"
                            readonly
                            required
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker color="purple lighten-1" v-model="userCampInfo.birthday">
                          <v-spacer></v-spacer>
                          <v-btn text small color="grey" @click="birthdayMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            small
                            color="purple"
                            @click="$refs.birthdayMenu.save(userCampInfo.birthday)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                      <v-text-field
                        v-model="userCampInfo.id_number"
                        :counter="18"
                        :rules="rules.id_number"
                        :label="infoFields.basic.id_number"
                        required
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('contact')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="contact">
                  <v-card flat>
                    <v-card-text>
                      <v-text-field
                        v-model="userCampInfo.phone"
                        :counter="11"
                        :rules="rules.phone"
                        :label="infoFields.contact.phone"
                        prefix="+86"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.email"
                        :counter="40"
                        :rules="rules.email"
                        :label="infoFields.contact.email"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="userCampInfo.addr_district"
                        :counter="40"
                        :rules="rules.required"
                        :label="infoFields.contact.addr_district"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.addr_detail"
                        :counter="40"
                        :rules="rules.required"
                        :label="infoFields.contact.addr_detail"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.addr_en"
                        :counter="100"
                        :rules="rules.required"
                        :label="infoFields.contact.addr_en"
                        required
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('school')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="school">
                  <v-card flat>
                    <v-card-text>
                      <v-text-field
                        v-model="userCampInfo.university_cn"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.school.university_cn"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.university_en"
                        :counter="100"
                        :rules="rules.required"
                        :label="infoFields.school.university_en"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.department_cn"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.school.department_cn"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.department_en"
                        :counter="100"
                        :rules="rules.required"
                        :label="infoFields.school.department_en"
                        required
                      ></v-text-field>
                      <v-row class="ma-2">
                        <v-switch
                          v-model="userCampInfo.is_special_plan"
                          :label="infoFields.school.is_special_plan"
                          @change="toggleRanking"
                          color="purple"
                          inset
                        >
                        </v-switch>
                      </v-row>
                      <v-text-field
                        v-model="userCampInfo.class_cn"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.school.class_cn"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.class_en"
                        :counter="40"
                        :rules="rules.required"
                        :label="infoFields.school.class_en"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="userCampInfo.student_number"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.school.student_number"
                        required
                      ></v-text-field>

                      <v-menu
                        ref="enrollmentMenu"
                        v-model="enrollmentMenu"
                        :return-value.sync="userCampInfo.year_of_enrollment"
                        :close-on-content-click="false"
                        top
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="userCampInfo.year_of_enrollment"
                            :label="infoFields.school.year_of_enrollment"
                            prepend-icon="mdi-calendar"
                            :rules="rules.required"
                            readonly
                            required
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="purple lighten-1"
                          type="month"
                          :min="(currentYear - 6).toString()"
                          :max="currentYear.toString()"
                          v-model="userCampInfo.year_of_enrollment"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text small color="grey" @click="enrollmentMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            small
                            color="purple"
                            @click="$refs.enrollmentMenu.save(userCampInfo.year_of_enrollment)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>

                      <v-menu
                        ref="graduationMenu"
                        v-model="graduationMenu"
                        :return-value.sync="userCampInfo.year_of_graduation"
                        :close-on-content-click="false"
                        top
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="userCampInfo.year_of_graduation"
                            :label="infoFields.school.year_of_graduation"
                            prepend-icon="mdi-calendar"
                            :rules="rules.required"
                            readonly
                            required
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          color="purple lighten-1"
                          type="month"
                          :min="currentYear.toString()"
                          :max="(currentYear + 5).toString()"
                          v-model="userCampInfo.year_of_graduation"
                        >
                          <v-spacer></v-spacer>
                          <v-btn text small color="grey" @click="graduationMenu = false">
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            small
                            color="purple"
                            @click="$refs.graduationMenu.save(userCampInfo.year_of_graduation)"
                          >
                            OK
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('degree')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="degree">
                  <v-card flat>
                    <v-card-text>
                      <v-text-field
                        v-model="userCampInfo.major_cn"
                        :counter="20"
                        :rules="rules.required"
                        :label="infoFields.degree.major_cn"
                        required
                      ></v-text-field>
                      <v-text-field
                        v-model="userCampInfo.major_en"
                        :counter="100"
                        :rules="rules.required"
                        :label="infoFields.degree.major_en"
                        required
                      ></v-text-field>

                      <v-checkbox
                        v-model="userCampInfo.has_double_degree"
                        label="是否有双学位/Double Degree"
                        required
                      ></v-checkbox>
                      <div v-if="userCampInfo.has_double_degree">
                        <v-text-field
                          v-model="userCampInfo.double_degree_cn"
                          :counter="20"
                          :rules="rules.required"
                          :label="infoFields.degree.double_degree_cn"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="userCampInfo.double_degree_en"
                          :counter="100"
                          :rules="rules.required"
                          :label="infoFields.degree.double_degree_en"
                          required
                        ></v-text-field>
                      </div>

                      <v-checkbox
                        v-model="userCampInfo.has_second_degree"
                        label="是否有二学位/Second Degree"
                        required
                      ></v-checkbox>
                      <div v-if="userCampInfo.has_second_degree">
                        <v-text-field
                          v-model="userCampInfo.second_degree_cn"
                          :counter="20"
                          :rules="rules.required"
                          :label="infoFields.degree.second_degree_cn"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="userCampInfo.second_degree_en"
                          :counter="100"
                          :rules="rules.required"
                          :label="infoFields.degree.second_degree_en"
                          required
                        ></v-text-field>
                      </div>

                      <v-checkbox
                        v-model="userCampInfo.has_minor_degree"
                        label="是否有辅修学位/Minor"
                        required
                      ></v-checkbox>
                      <div v-if="userCampInfo.has_minor_degree">
                        <v-text-field
                          v-model="userCampInfo.minor_degree_cn"
                          :counter="20"
                          :rules="rules.required"
                          :label="infoFields.degree.minor_degree_cn"
                          required
                        ></v-text-field>
                        <v-text-field
                          v-model="userCampInfo.minor_degree_en"
                          :counter="100"
                          :rules="rules.required"
                          :label="infoFields.degree.minor_degree_en"
                          required
                        ></v-text-field>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('gpa')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="gpa">
                  <v-card flat>
                    <v-card-text>
                      <v-alert type="info" dense>
                        两种排名至少需要填写一种，并须与提供的排名证明保持一致
                      </v-alert>
                      <v-row class="ma-2">
                        <v-checkbox
                          v-model="hasOverallGPARanking"
                          :disabled="noRanking"
                          @change="toggleRanking"
                        >
                        </v-checkbox>
                        <v-text-field
                          v-model.number="userCampInfo.overall_gpa_ranking"
                          :rules="rules.nullable_number"
                          :disabled="!hasOverallGPARanking"
                        >
                          <template #label>
                            <span>
                              全部课程加权学分绩<strong class="red--text">排名</strong>/Overall GPA
                              ranking
                            </span>
                          </template>
                        </v-text-field>
                      </v-row>

                      <v-row class="ma-2">
                        <v-checkbox
                          v-model="hasMajorGPARanking"
                          :disabled="noRanking"
                          @change="toggleRanking"
                        ></v-checkbox>
                        <v-text-field
                          v-model.number="userCampInfo.major_gpa_ranking"
                          :rules="rules.nullable_number"
                          :disabled="!hasMajorGPARanking"
                        >
                          <template #label>
                            <span>
                              必修+专业限选课加权学分绩<strong class="red--text">排名</strong>/GPA
                              ranking for required and major elective courses
                            </span>
                          </template>
                        </v-text-field>
                      </v-row>

                      <v-row class="ma-2">
                        <v-checkbox v-model="noRanking">
                          <template #label>
                            <span>
                              学院不提供排名(<strong class="red--text">校内强基计划可选</strong>)
                            </span>
                          </template>
                        </v-checkbox>
                      </v-row>

                      <v-text-field
                        v-model.number="userCampInfo.major_students"
                        :rules="rules.number"
                        :label="infoFields.ranking.major_students"
                        required
                      ></v-text-field>

                      <v-textarea
                        v-model="userCampInfo.special_notes"
                        :counter="200"
                        auto-grow
                        outlined
                        :label="infoFields.ranking.special_notes + '（中英文不限；选填）'"
                      ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('curriculum')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="curriculum">
                  <v-card flat>
                    <v-card-text>
                      <v-data-table
                        dense
                        :headers="curriculumHeaders"
                        :items="coreCurriculum"
                        disable-pagination
                        hide-default-footer
                      >
                        <template v-slot:top>
                          <v-toolbar flat dense>
                            <v-toolbar-title
                              v-text="infoFields.curriculum.core_curriculum"
                            ></v-toolbar-title>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-spacer></v-spacer>
                            <v-dialog persistent v-model="curriculumDialog" max-width="400px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                  Add
                                </v-btn>
                              </template>
                              <v-card>
                                <v-card-title v-text="curriculumTitle"> </v-card-title>

                                <v-card-text>
                                  <v-text-field
                                    v-model="newCurriculum.name_cn"
                                    :rules="rules.required"
                                    :label="curriculumNames.name_cn"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newCurriculum.name_en"
                                    :rules="rules.required"
                                    :label="curriculumNames.name_en"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newCurriculum.credit"
                                    :rules="rules.required"
                                    :label="curriculumNames.credit"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newCurriculum.grade"
                                    :rules="rules.required"
                                    :label="curriculumNames.grade"
                                  ></v-text-field>
                                  <v-text-field
                                    v-model="newCurriculum.remark"
                                    :label="curriculumNames.remark"
                                  ></v-text-field>
                                </v-card-text>

                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="curriculumClose">
                                    Cancel
                                  </v-btn>
                                  <v-btn
                                    :disabled="
                                      !newCurriculum.name_cn ||
                                      !newCurriculum.name_en ||
                                      !newCurriculum.credit ||
                                      !newCurriculum.grade
                                    "
                                    color="blue darken-1"
                                    text
                                    @click="curriculumSave"
                                  >
                                    Save
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                            <v-dialog v-model="curriculumDialogDelete" max-width="500px">
                              <v-card>
                                <v-card-title class="headline"
                                  >Are you sure you want to delete this item?</v-card-title
                                >
                                <v-card-actions>
                                  <v-spacer></v-spacer>
                                  <v-btn color="blue darken-1" text @click="curriculumCloseDelete"
                                    >Cancel</v-btn
                                  >
                                  <v-btn color="blue darken-1" text @click="curriculumDeleteConfirm"
                                    >OK</v-btn
                                  >
                                  <v-spacer></v-spacer>
                                </v-card-actions>
                              </v-card>
                            </v-dialog>
                          </v-toolbar>
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                          <v-icon small class="mr-2" @click="curriculumEditItem(item)">
                            mdi-pencil
                          </v-icon>
                          <v-icon small @click="curriculumDeleteItem(item)"> mdi-delete </v-icon>
                        </template>
                      </v-data-table>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('statement')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="statement">
                  <v-card flat>
                    <v-card-text>
                      <v-textarea
                        v-model="userCampInfo.personal_statement"
                        :rules="rules.required"
                        :counter="100000"
                        auto-grow
                        rows="16"
                        required
                        outlined
                        dense
                        :label="infoFields.personal.personal_statement"
                      ></v-textarea>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('recommend')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="recommend">
                  <v-card flat>
                    <v-card-text>
                      <v-card class="ma-2">
                        <v-card-text>
                          <v-text-field
                            v-model="userCampInfo.recommander_a_name"
                            :counter="20"
                            :rules="rules.required"
                            :label="infoFields.recommend.recommander_a_name"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="userCampInfo.recommander_a_phone"
                            :counter="11"
                            prefix="+86"
                            :rules="rules.phone"
                            :label="infoFields.recommend.recommander_a_phone"
                            required
                          ></v-text-field>
                          <v-checkbox
                            v-model="recommanderAWithoutMail"
                            label="邮寄纸质推荐信/Recommendation letter delivery by MAIL"
                            required
                          ></v-checkbox>
                          <v-alert type="info" v-if="recommanderAWithoutMail"
                            ><a href="/misc/ref.doc" target="_blank">点此</a>下载推荐信模板</v-alert
                          >
                          <v-text-field
                            v-else
                            v-model="userCampInfo.recommander_a_email"
                            :counter="40"
                            :rules="recommanderAWithoutMail ? [] : rules.email"
                            :label="infoFields.recommend.recommander_a_email"
                            :disabled="recommanderAWithoutMail"
                            required
                          ></v-text-field>
                        </v-card-text>
                      </v-card>

                      <v-card class="ma-2">
                        <v-card-text>
                          <v-text-field
                            v-model="userCampInfo.recommander_b_name"
                            :counter="20"
                            :rules="rules.required"
                            :label="infoFields.recommend.recommander_b_name"
                            required
                          ></v-text-field>
                          <v-text-field
                            v-model="userCampInfo.recommander_b_phone"
                            :counter="11"
                            prefix="+86"
                            :rules="rules.phone"
                            :label="infoFields.recommend.recommander_b_phone"
                            required
                          ></v-text-field>
                          <v-checkbox
                            v-model="recommanderBWithoutMail"
                            label="邮寄纸质推荐信/Recommendation letter delivery by MAIL"
                            required
                          ></v-checkbox>
                          <v-alert type="info" v-if="recommanderBWithoutMail"
                            ><a href="/misc/ref.doc" target="_blank">点此</a>下载推荐信模板</v-alert
                          >
                          <v-text-field
                            v-else
                            v-model="userCampInfo.recommander_b_email"
                            :counter="40"
                            :rules="recommanderBWithoutMail ? [] : rules.email"
                            :label="infoFields.recommend.recommander_b_email"
                            :disabled="recommanderBWithoutMail"
                            required
                          ></v-text-field>
                        </v-card-text>
                      </v-card>

                      <v-checkbox
                        v-model="hasRecommenderC"
                        label="推荐人3(选填)/Recommender C (Optinal)"
                        required
                      ></v-checkbox>
                      <div v-if="hasRecommenderC">
                        <v-card class="ma-2">
                          <v-card-text>
                            <v-text-field
                              v-model="userCampInfo.recommander_c_name"
                              :counter="20"
                              :label="infoFields.recommend.recommander_c_name"
                            ></v-text-field>
                            <v-text-field
                              v-model="userCampInfo.recommander_c_phone"
                              prefix="+86"
                              :counter="11"
                              :label="infoFields.recommend.recommander_c_phone"
                            ></v-text-field>
                            <v-checkbox
                              v-model="recommanderCWithoutMail"
                              label="邮寄纸质推荐信/Recommendation letter delivery by MAIL"
                              required
                            ></v-checkbox>
                            <v-alert type="info" v-if="recommanderCWithoutMail"
                              ><a href="/misc/ref.doc" target="_blank">点此</a
                              >下载推荐信模板</v-alert
                            >
                            <v-text-field
                              v-else
                              v-model="userCampInfo.recommander_c_email"
                              :counter="40"
                              :label="infoFields.recommend.recommander_c_email"
                              :disabled="recommanderCWithoutMail"
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('upload')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="upload">
                  <v-card flat>
                    <v-card-text>
                      <v-alert type="error" v-if="infoStatus == ''"
                        >上传材料前请先保存/Please save before upload</v-alert
                      >
                      <v-alert type="info"
                        >若有多个文件请合并成单个 pdf 上传/Please merge files into single
                        pdf</v-alert
                      >
                      <v-row
                        justify="center"
                        align="center"
                        v-for="(item, field) in fileFields"
                        :key="field"
                      >
                        <v-col cols="11">
                          <v-text-field
                            disabled
                            :value="userFiles[field] ? userFiles[field].name : ''"
                            prepend-icon="mdi-paperclip"
                            :label="item.desc"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="1">
                          <v-tooltip top v-if="userFiles[field]">
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon v-bind="attrs" v-on="on" @click="handleDelete(field)">
                                mdi-delete</v-icon
                              >
                            </template>
                            <span>删除/Remove</span>
                          </v-tooltip>
                          <v-tooltip top v-else>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                v-bind="attrs"
                                v-on="on"
                                @click="handleUpload(field, item.desc, item.types)"
                                >mdi-upload</v-icon
                              >
                            </template>
                            <span>上传/Upload</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn @click="gotoTab('other')">下一步/Next &rarr;</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item value="other">
                  <v-card flat>
                    <v-card-text>
                      <v-textarea
                        v-for="(desc, field) in infoFields.other"
                        :key="field"
                        v-model="userCampInfo[field]"
                        :counter="5000"
                        auto-grow
                        outlined
                        :hint="desc"
                        :label="desc"
                      ></v-textarea>

                      <v-text-field
                        v-model="userCampInfo.access"
                        :counter="20"
                        label="您获得夏令营信息的渠道/How do you know the summer camp"
                      ></v-text-field>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        :disabled="!formValid"
                        @click="handleSave(true)"
                        v-if="infoStatus == 'saved' || infoStatus == 'returned' || infoStatus == ''"
                      >
                        保存&amp;预览/Save&amp;Preview &rarr;</v-btn
                      >
                      <v-btn color="primary" to="/apply/detail" v-else>返回</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </v-form>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import requests from "@/http";
import consts from "@/consts";

export default {
  data: () => ({
    rules: consts.INPUT_RULES,
    infoFields: consts.CAMP_INFO,
    fileFields: consts.CAMP_FILES,
    fileTypes: consts.FILE_TYPES,
    curriculumNames: consts.CURRICULUM_NAMES,
    curriculumHeaders: consts.CURRICULUM_HEADERS,
    alerts: null,
    messages: null,
    selectGender: [
      { desc: "男/Male", value: 1 },
      { desc: "女/Female", value: 2 },
    ],
    deleteDialog: false,
    deleteField: "",
    uploadDialog: false,
    uploadField: "",
    uploadDesc: "",
    uploadTypes: "",
    uploadFile: null,
    isUploading: false,
    curriculumDialog: false,
    curriculumDialogDelete: false,
    curriculumEditedIndex: -1,
    tab: "basic",
    currentYear: new Date().getFullYear(),
    birthdayMenu: false,
    enrollmentMenu: false,
    graduationMenu: false,

    hasOverallGPARanking: false,
    hasMajorGPARanking: false,
    noRanking: false,

    newCurriculum: {
      name_cn: "",
      name_en: "",
      credit: "",
      grade: "",
      remark: "",
    },
    defaultCurriculum: {
      name_cn: "",
      name_en: "",
      credit: "",
      grade: "",
      remark: "",
    },
    coreCurriculum: [],
    statementAgreement: false,
    formValid: false,
    userFiles: {
      id_photo: null,
      resume: null,
      transcript: null,
      ranking_certificate: null,
      english_exams: null,
      other_docs: null,
    },

    infoStatus: "",
    hasRecommenderC: false,
    recommanderAWithoutMail: false,
    recommanderBWithoutMail: false,
    recommanderCWithoutMail: false,
    userCampInfo: {
      name_cn: "",
      first_name: "",
      last_name: "",
      gender: null,
      id_photo: null,
      birthday: null,
      id_number: "",

      phone: "",
      email: "",
      addr_district: "",
      addr_detail: "",
      addr_en: "",

      university_cn: "",
      university_en: "",
      department_cn: "",
      department_en: "",
      is_special_plan: false,
      class_cn: "",
      class_en: "",
      student_number: "",

      major_cn: "",
      major_en: "",
      has_double_degree: false,
      double_degree_cn: "",
      double_degree_en: "",
      has_second_degree: false,
      second_degree_cn: "",
      second_degree_en: "",
      has_minor_degree: false,
      minor_degree_cn: "",
      minor_degree_en: "",
      year_of_enrollment: "",
      year_of_graduation: "",

      core_curriculum: "[]",

      special_notes: "",
      overall_gpa_ranking: null,
      major_gpa_ranking: null,
      major_students: null,

      personal_statement: "",

      recommander_a_name: "",
      recommander_a_phone: "",
      recommander_a_email: "",
      recommander_b_name: "",
      recommander_b_phone: "",
      recommander_b_email: "",
      recommander_c_name: "",
      recommander_c_phone: "",
      recommander_c_email: "",

      resume: null,
      transcript: null,
      ranking_certificate: null,
      english_exams: null,

      awards: "",
      research: "",
      publication: "",
      other_docs: null,
      access: "",
    },
  }),
  created() {
    requests.get(`/api/camp/info`).then((res) => {
      this.infoStatus = res.data.status;
      this.userCampInfo = res.data.info;
      if (this.userCampInfo.overall_gpa_ranking !== null) {
        this.hasOverallGPARanking = true;
      }
      if (this.userCampInfo.major_gpa_ranking !== null) {
        this.hasMajorGPARanking = true;
      }
      this.coreCurriculum = JSON.parse(this.userCampInfo.core_curriculum);
      this.getUserFiles();
    });
  },
  computed: {
    curriculumTitle() {
      return this.curriculumEditedIndex === -1 ? "Add" : "Edit";
    },
  },
  methods: {
    getUserFiles() {
      requests
        .get(`/api/users/files`)
        .then((res) => {
          const files = res.data.files;
          Object.keys(this.userFiles).forEach((k) => {
            const id = this.userCampInfo[k];
            if (id) {
              this.userFiles[k] = files[id];
            }
          });
        })
        .catch(() => {
          console.log("get user files error!");
        });
    },
    disableSubmit() {},

    toggleRanking() {
      if (this.noRanking) {
        this.hasMajorGPARanking = false;
        this.hasOverallGPARanking = false;
        this.userCampInfo.overall_gpa_ranking = null;
        this.userCampInfo.major_gpa_ranking = null;
        return;
      }
      if (!this.hasMajorGPARanking) {
        this.userCampInfo.major_gpa_ranking = null;
      }
      if (!this.hasOverallGPARanking) {
        this.userCampInfo.overall_gpa_ranking = null;
      }
    },

    handleSave(preview) {
      if (this.recommanderAWithoutMail) {
        this.userCampInfo.recommander_a_email = "~";
      }
      if (this.recommanderBWithoutMail) {
        this.userCampInfo.recommander_b_email = "~";
      }
      if (this.recommanderCWithoutMail) {
        this.userCampInfo.recommander_c_email = "~";
      }
      if (!this.hasRecommenderC) {
        this.userCampInfo.recommander_c_name = "";
        this.userCampInfo.recommander_c_phone = "";
        this.userCampInfo.recommander_c_email = "";
      }
      requests
        .post(`/api/camp/info`, this.userCampInfo)
        .then(() => {
          alert("保存成功/Saved successfully!");
          if (preview) {
            this.$router.push("/apply/detail");
          }
        })
        .catch((err) => {
          if (err.response.status == 422) {
            alert("invalid submit form:" + JSON.stringify(err.response.data.detail));
          }
        });
    },
    gotoTab(tab) {
      this.tab = tab;
    },

    selectFile(file) {
      this.uploadFile = file;
    },
    handleDelete(field) {
      this.deleteField = field;
      this.deleteDialog = true;
    },
    deleteClose() {
      this.deleteDialog = false;
      this.$nextTick(() => {
        this.deleteField = "";
      });
    },
    deleteConfirm() {
      requests
        .post(`/api/camp/files/delete/${this.deleteField}`)
        .then(() => {
          this.userCampInfo[this.deleteField] = null;
          this.userFiles[this.deleteField] = null;
          this.deleteClose();
        })
        .catch((err) => {
          alert(err.response.data.detail);
        });
    },

    handleUpload(field, desc, types) {
      this.uploadField = field;
      this.uploadDesc = desc;
      this.uploadTypes = types;
      this.uploadDialog = true;
    },
    uploadClose() {
      this.uploadDialog = false;
      this.$nextTick(() => {
        this.uploadField = "";
        this.uploadDesc = "";
        this.uploadFile = null;
        this.$refs.fileupload.reset();
      });
    },
    uploadSubmit() {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("file", this.uploadFile);
      requests
        .post(`/api/camp/files/upload/${this.uploadField}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          this.isUploading = false;
          this.userCampInfo[this.uploadField] = res.data.file.id;
          this.userFiles[this.uploadField] = res.data.file;
          this.uploadClose();
        })
        .catch((err) => {
          this.isUploading = false;
          alert(err.response.data.detail);
        });
    },

    curriculumEditItem(item) {
      this.curriculumEditedIndex = this.coreCurriculum.indexOf(item);
      this.newCurriculum = Object.assign({}, item);
      this.curriculumDialog = true;
    },
    curriculumDeleteItem(item) {
      this.curriculumEditedIndex = this.coreCurriculum.indexOf(item);
      this.newCurriculum = Object.assign({}, item);
      this.curriculumDialogDelete = true;
    },
    curriculumDeleteConfirm() {
      this.coreCurriculum.splice(this.curriculumEditedIndex, 1);
      this.curriculumCloseDelete();
    },
    curriculumClose() {
      this.curriculumDialog = false;
      this.$nextTick(() => {
        this.newCurriculum = Object.assign({}, this.defaultCurriculum);
        this.$refs.form.resetValidation();
        this.curriculumEditedIndex = -1;
        this.userCampInfo.core_curriculum = JSON.stringify(this.coreCurriculum);
      });
    },
    curriculumCloseDelete() {
      this.curriculumDialogDelete = false;
      this.$nextTick(() => {
        this.newCurriculum = Object.assign({}, this.defaultCurriculum);
        this.$refs.form.resetValidation();
        this.curriculumEditedIndex = -1;
        this.userCampInfo.core_curriculum = JSON.stringify(this.coreCurriculum);
      });
    },
    curriculumSave() {
      if (this.curriculumEditedIndex > -1) {
        Object.assign(this.coreCurriculum[this.curriculumEditedIndex], this.newCurriculum);
      } else {
        this.coreCurriculum.push(this.newCurriculum);
      }
      this.curriculumClose();
    },
  },
};
</script>
